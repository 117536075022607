*, *::before, *::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html body {
  padding: 0;
  margin: 0;
  overflow-x: inherit;
}
